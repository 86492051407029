import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import gerdali from "./images/gerdali.png";
import capture from "./images/Capture.jpg";
import bg from "./images/bg.jpg";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton, WalletDisconnectButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
 && {
  margin: 1vh;
  margin-bottom: 1vh; 
  background-image: linear-gradient(to right, #ff8c27 , #ffaa16);
  min-width: 130px; 
  border-radius: 2.25rem; 
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  justify-content: center;
 }
`;

const DisconnectButton = styled(WalletDisconnectButton)`
 && {
  margin: 1vh;
  margin-bottom: 1vh; 
  background-image: linear-gradient(to right, #ff8c27 , #ffaa16);
  min-width: 187px; 
  border-radius: 2.25rem; 
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  justify-content: center;
 }
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)`
&& {
  margin: 1vh;
  margin-bottom: 1vh; 
  background-image: linear-gradient(to right, #ff8c27 , #ffaa16);
  min-width: 60%; 
  border-radius: 2.25rem; 
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
 }`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);
      setIsActive(false);
      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>

      <div className="container-fluid" style={{ padding: "unset", minWidth: "350px" }}>
        <header style={{ backgroundColor: "rgb(224, 52, 224)" }}>
          <div style={{ width: "85%", margin: "auto" }}>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 ">
                <span >
                  <a href="https://www.instagram.com/solanawondersnft/">
                    <i className="fab fa-instagram" style={{ color: "#ffc404", fontSize: "35px", marginTop: "1vh", marginBottom: "1vh", paddingRight: "3vh" }}></i>
                  </a>
                  <a href="https://discord.gg/ahqsYVETnE"><i className="fab fa-discord" style={{ color: "#ffc404", fontSize: "35px", marginTop: "1vh", marginBottom: "1vh", paddingRight: "3vh" }}></i>
                  </a>
                  <a href="https://twitter.com/solanawonders">
                    <i className="fab fa-twitter" style={{ color: "#ffc404", fontSize: "35px", marginTop: "1vh", marginBottom: "1vh", paddingRight: "3vh" }}></i>
                  </a>
                </span>
              </div>

              <div className="col-lg-5 col-md-5 col-sm-12  text-center" style={{ paddingTop: "2vh", color: "rgb(83, 28, 146)", fontSize: "20px" }} >
                <strong> Welcome to Solana Wonders</strong>
              </div>
              <div className="col-lg-4 col-md-4 col-md-12 text-center">
              {!wallet ? (
                <ConnectButton>Connect Wallet</ConnectButton>
              ) : (
                <DisconnectButton><p style={{margin: 0, padding: 0, color: "blue"}}>{shortenAddress(wallet.publicKey.toString())}</p>&nbsp; Disconnect Wallet</DisconnectButton>
              )
              }
              </div>
            </div>
          </div>

        </header>

        <section className="bgimg" style={{ backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "cover", height: "100%", width: "100%" }}>
          <div style={{ width: "85%", margin: "auto" }}>
            <div className="row" style={{ paddingTop: "10vh", paddingBottom: "10vh" }}>


              <div className="col-lg-5 col-md-12 col-sm-12 mr-auto " style={{ marginTop: "20vh", verticalAlign: "middle" }}>
                <div className="card" style={{ backgroundColor: "rgba(99, 15, 194, 0.151)", borderRadius: "2.25rem"}}>
                  <div className="card" style={{ backgroundColor: "rgba(52, 20, 88, 0.507)", margin: "2vh", color: "white", borderRadius: "2.25rem" }}>
                    <h3 className="text-center" style={{ fontSize: "23px" }}> <strong>Our first exclusive 111 NFT mint </strong> </h3>
                    <h3 className="text-center" style={{ fontSize: "23px" }}> <strong>Minting price: 1 SOL per NFT</strong> </h3>

                    <p className="text-center">{wallet && <p>Total Available: {itemsAvailable}</p>}</p>
                    <p className="text-center">{wallet && <p>Redeemed: {itemsRedeemed}</p>}</p>
                    <p className="text-center">{wallet && <p>Remaining: {itemsRemaining}</p>}</p>
                  </div>
                  <div className="cbtn">
                  <MintContainer>
                    {!wallet ? (
                      <div style={{display: "none"}}>mint area</div>
                    ) : (
                      <MintButton
                        className="btn"
                        disabled={isSoldOut || isMinting || !isActive}
                        onClick={onMint}
                        variant="contained"
                      >
                        {isSoldOut ? (
                          "SOLD OUT"
                        ) : isActive ? (
                          isMinting ? (
                            <CircularProgress />
                          ) : (
                            "MINT"
                          )
                        ) : (
                          <Countdown
                            date={startDate}
                            onMount={({ completed }) => completed && setIsActive(true)}
                            onComplete={() => setIsActive(true)}
                            renderer={renderCounter}
                          />
                        )}
                      </MintButton>
                    )}
                  </MintContainer>
                  </div>
                </div>


              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 ">
                <img src={gerdali} style={{ width: "100%", height: "auto" }} alt="" />
              </div>

            </div>
          </div>
        </section>
      </div >
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main >
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
